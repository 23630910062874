<template>
  <v-dialog
    width="600"
    v-model="dialog"
    >
    <v-card
      class="rounded-b-0"
      >
      <v-dialog
        width="600"
        v-model="modal"
        >
        <v-card>
          <v-card-title
            class="justify-center"
            >
            ¡Así que quieres ser <span class="ml-1 font-weight-medium secondary--text">Partner</span>!
          </v-card-title>
          <v-card-text
            class="text-center"
            >
            <create-store
              text="Tranquilo, sólo te tomará un segundo y estarás dentro, nada más necesitamos saber un poco más sobre tu tienda."
              @next="$router.go()"
              ></create-store>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-card-title
        class="justify-center"
        >
        Selección de perfil
      </v-card-title>
      <v-card-text
        class="pa-0"
        >
        <v-list>
          <v-list-item-group
            color="primary"
            >
            <v-list-item
              v-for="profile in currentUser.profiles"
              v-if="profile.id != currentUser.selectedProfile.id"
              :key="profile.id"
              @click="changeProfile(profile.id)"
              >
              <v-list-item-avatar>
                <v-img
                  v-if="profile.picture"
                  :src="url + profile.picture.thumbUrl"
                  ></v-img>
                <v-icon
                  v-else
                  >
                  {{ profile.profilableType == 'Store' ? 'mdi-storefront' : 'mdi-account-circle' }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ profile.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="currentUser.profiles.length < 3"
              @click="modal = true"
              >
              <v-list-item-avatar>
                <v-icon
                  color="primary"
                  >
                  mdi-storefront
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  class="primary--text"
                  >
                  <template
                    v-if="currentUser.profiles.length == 1"
                    >
                    ¡Quiero ser <span class="secondary--text font-weight-medium">Partner</span>!
                  </template>

                  <template
                    v-else
                    >
                    ¡Quiero sumar un <span class="secondary--text font-weight-medium">Partner</span>!
                  </template>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              @click="signout"
              >
              <v-list-item-avatar>
                <v-icon>
                  mdi-account-off
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  Cerrar sesión
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { ChangeSelectedProfile } from '@/graphql/mutations/users'

const CreateStore = () => import('@/components/shared/onboarding/Store')

export default {
  data: () => ({
    modal: false,
    dialog: false
  }),

  computed: {
    ...mapGetters(['currentUser', 'url'])
  },

  methods: {
    signout () {
      localStorage.removeItem('auth_token')
      localStorage.removeItem('order_token')
      this.$router.go()
    },

    changeProfile (id) {
      this.loading = true

      this.$apollo.mutate({
        mutation: ChangeSelectedProfile,
        variables: {
          input: {
            id: id
          }
        }
      }).then ( res => {
        this.$router.push({name:"home", hash:"#change_profile"})
      })
    }
  },

  components: {
    CreateStore
  }
}
</script>
